/* General Styles */

/* Wrapper style - provides padding and a specific background color for encapsulating elements */
.wrapper {
    padding: 120px;
    /* Generous padding for a spacious layout */
    background-color: #203827;
    /* Dark green background for contrast */
}

/* Media Queries */

/* Adjust content padding on screens smaller than 1400px */
@media screen and (max-width:1400px) {
    .content {
        padding-left: 0;
        /* Removes padding on the left for smaller screens */
    }
}

/* Flexbox layout for button grouping, centering items horizontally and vertically */
.buttonSwitch {
    display: flex;
    justify-content: center;
    align-items: center;
}
