/* Title style - applied to main heading elements within the application */
.title {
    color: white;
    /* White text for high contrast against dark background */
    border-bottom: 2px solid #61ce70;
    /* Light green bottom border for visual separation */
    font-family: "Readex Pro Bold";
    /* Custom bold font for emphasis */
    font-size: 3rem;
    /* Large text size for heading */
    margin-bottom: 40px;
    /* Space below the title for clear separation */
}

/* Additional subtitle styles for different sections */
.subTitle {
    font-family: "Readex Pro Bold";
    font-size: 2rem;
    /* Larger text for important subtitles */
    color: white;
    padding-left: 100px;
    /* Larger left padding for distinct sectioning */
    margin-top: 5px;
    padding-top: 5px;
}

/* Secondary content styles for further details and insights */
.content {
    padding-left: 100px;
    /* Consistent left padding with subTitleSecond */
    color: #abbaaf;
    /* Muted green text for subtlety */
    font-size: 1.3rem;
    /* Comfortable reading size */
    margin-bottom: 20px;
    /* Spacing below paragraphs */
}

/* Center alignment styles for content within divs */
.centercenter {
    display: flex;
    justify-content: flex-start;
    /* Aligns children to the start of the flex container */
    align-items: center;
    /* Centers children vertically */
}

/* Hover effect for the button */
.myBut:hover {
    color: white;
    /* Changes text color to white on hover */
    background-color: #354d3c;
    /* Dark green background on hover */
}

/* Icon styling in the button */
.myBut i {
    margin-left: 2%;
    /* Slight separation from the text */
    transform: rotate(-45deg);
    /* Rotates icon for visual flair */
}

/* Styling for action button */
.myBut {
    cursor: pointer;
    /* Indicates the element is clickable */
    margin-left: 100px;
    /* Left margin for alignment */
    margin-top: 20px;
    /* Top margin for spacing */
    background-color: #61ce70;
    /* Light green background for visibility */
    border: none;
    /* No border for a clean appearance */
    display: flex;
    /* Flexbox for internal centering */
    align-items: center;
    /* Vertically aligns text and icons inside the button */
    justify-content: space-around;
    /* Evenly spaces elements within the button */
    padding: 10px;
    /* Padding for a tactile feel */
    font-family: "Nunito Sans";
    /* Readable font style */
    font-size: 1.2rem;
    /* Size of the button text */
    font-weight: 600;
    /* Slightly bolder text for emphasis */
    color: #203827;
    /* Dark green text for contrast */
    border-radius: 20px;
    /* Rounded corners for a friendly appearance */
    width: 450px;
    /* Fixed width for consistency */
}