/* Title style - applied to main heading elements within the application */
.title {
    color: white;
    /* White text for high contrast against dark background */
    border-bottom: 2px solid #61ce70;
    /* Light green bottom border for visual separation */
    font-family: "Readex Pro Bold";
    /* Custom bold font for emphasis */
    font-size: 3rem;
    /* Large text size for heading */
    margin-bottom: 40px;
    /* Space below the title for clear separation */
}

/* Additional styles for third type subtitles */
.subTitle {
    font-family: "Readex Pro Bold";
    font-size: 2rem;
    /* Larger text for clear readability */
    color: white;
    margin-top: 5px;
    padding-top: 5px;
}


/* Detailed content styles for third type */
.content {
    color: #abbaaf;
    /* Muted green text for thematic consistency */
    font-size: 1.3rem;
    /* Comfortable reading size */
    margin-bottom: 10px;
    /* Spacing below each item */
}

/* Styling for tables within contentThird */
.content th,
.content td {
    border: 1px solid rgb(160, 160, 160);
    /* Light grey borders for cell definition */
    padding: 8px 10px;
    /* Padding for cell content */
    box-sizing: border-box;
    text-align: center;
    /* Center aligns all text within cells */
    color: white;
    /* White text for contrast */
}

/* Header specific styles in tables */
.content th {
    font-size: 1.3rem;
    /* Larger text for header cells */
}

/* Data cell specific styles */
.content td {
    display: table-cell;
    vertical-align: inherit;
    /* Aligns text vertically to the inherit setting */
    unicode-bidi: isolate;
    /* Isolates the bidirectional algorithm for the cell */
    font-size: 1rem;
    /* Standard text size for data cells */
}

/* Styling for the entire table within contentThird */
.content table {
    border-collapse: collapse;
    /* Collapses border to single border shared between cells */
    border: 2px solid rgb(140, 140, 140);
    /* Darker grey border for overall table */
    font-family: sans-serif;
    /* Sans-serif font for readability */
    font-size: 0.8rem;
    /* Smaller text size for data density */
    letter-spacing: 1px;
    /* Slightly spaced letters for legibility */
    text-indent: initial;
    /* Default text indentation */
    border-spacing: 2px;
    /* Space between borders of adjacent cells */
    margin-right: 30px;
    /* Right margin for positioning */
    background-color: #354d3c;
    /* Dark green background for the table */
}


/* Flexbox layout for complex data presentations */
.wrapper {
    display: flex;
    justify-content: flex-start;
    /* Aligns items to the start */
    align-items: flex-start;
    /* Aligns items to the top */
    margin-top: 20px;
}


/* Margin style for content within contentThird */
.content_margin {
    margin-bottom: 10px;
    /* Space below the content */
}

