.wrapper{
    padding: 50px 0;
}

.title i{
    color:#90ce70;
}

.title {
    font-size: 1.3rem;
    font-family: "Readex Pro Bold";
}

.leftNav {
    border-radius: 25px;
    padding: 20px;
    box-shadow: 0 0 5px 5px #f9f9f9;
}

.rightContent{
    padding:0px 10px;
    height: 1500px;
}