/* Styles for the aligner container */
.aligner {
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  flex:1;
}

/* Styles for the main container */
.my_container {
  display: flex;
  width: fit-content;
  margin: 0 auto;
  gap: 20px;
}

/* Styles for button container */
.buttons {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: fit-content;
  margin: auto;
}

/* Media query for screens with maximum width of 770px */
@media (max-width: 770px) {
  .my_container {
    flex-direction: column;
    width: 100vw;
  }

  /* Adjustments for navigation logo and links */
  .nav-logo, .nav-links {
    font-size: 1rem;
  }
  /* Adjustments for the grid container */
  .grid {
    width: 100vw;
    height: 100vw;
    margin-top: 10px;
  }
  /* Adjustments for the keypad container */
  .keypad {
    grid-auto-flow: column;
    grid-template-columns: none;
    width: 100vw;
    gap: 5px;
    height: auto;
  }

  /* Adjustments for individual keys */
  .key {
    height: 50px;
  }

  /* Adjustments for function buttons */
  .fn-btn {
    width: 50px;
    height: 50px;
  }
}

.sudokuApp{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.SD-but{
  border: none;
  background-color: green;
  color: white;
  border-radius: 15px;
  padding: 10px 20px;
  margin-top: 10px;
  transition: 0.2s;
  font-size: 1.2rem;
  font-weight: 500;
  box-shadow: 0 2px 5px 1px black;
}

.SD-but:hover {
  background-color: rgb(94, 248, 94);
  color: black;
}


.SD-title{
  font-size: 1.5rem;
  font-weight: 700;
}

.SD-moregames{
  margin-left: 15px;
}