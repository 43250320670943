.image {
    width: 100%; /* Set width to 100% */
    border-radius: 20px; /* Apply border radius */
    margin-bottom: 30px; /* Add margin at the bottom */
}

.title {
    font-size: 3rem; /* Set font size */
    font-family: "Readex Pro Bold"; /* Specify font family */
    margin-bottom: 15px; /* Add margin at the bottom */
}

.block_description {
    border-left: 5px solid #61ce70; /* Add left border with color */
    font-size: 1.3rem; /* Set font size */
    font-family: "Nunito Sans"; /* Specify font family */
    padding: 10px 20px; /* Add padding */
    box-shadow: 0 0 10px 1px #f2f2f2; /* Apply box shadow */
    color: #a47979; /* Set text color */
    margin-bottom: 10px; /* Add margin at the bottom */
}

.aspect_description {
    color: #a47979; /* Set text color */
    font-size: 1.5rem; /* Set font size */
    font-family: "Nunito Sans"; /* Specify font family */
}

.descriptions li {
    font-size: 1.3rem; /* Set font size */
    padding: 15px 0; /* Add padding */
}

.keypoints {
    font-size: 1.3rem; /* Set font size */
}

.keypoints li {
    padding: 15px 0; /* Add padding */
}

.subtitle {
    font-size: 1.3rem; /* Set font size */
    font-family: "Readex Pro Bold"; /* Specify font family */
}

.button {
    background-color: #36d179; /* Set background color */
    border: 2px solid #36d179; /* Add border */
    box-sizing: border-box; /* Box sizing */
    border-radius: 10px; /* Apply border radius */
    padding: 10px 20px; /* Add padding */
    font-family: "Readex Pro Medium"; /* Specify font family */
    color: white; /* Set text color */
}

.button:hover {
    background-color: white; /* Change background color on hover */
    color: #36d179; /* Change text color on hover */
}

.button i {
    transform: rotate(-45deg); /* Rotate icon */
}

.tooltip {
    position: absolute; /* Set position */
    text-align: center; /* Align text */
    padding: 8px; /* Add padding */
    font: 12px sans-serif; /* Specify font */
    background: #F0F0F0; /* Set background color */
    border: 1px solid #333; /* Add border */
    border-radius: 5px; /* Apply border radius */
    pointer-events: none; /* Disable pointer events */
}