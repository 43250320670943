/* General Styles */

/* Wrapper style - provides padding and a specific background color for encapsulating elements */
.wrapper {
    padding: 120px;
    /* Generous padding for a spacious layout */
    background-color: #203827;
    /* Dark green background for contrast */
}

/* Title style - applied to main heading elements within the application */
.title {
    color: white;
    /* White text for high contrast against dark background */
    border-bottom: 2px solid #61ce70;
    /* Light green bottom border for visual separation */
    font-family: "Readex Pro Bold";
    /* Custom bold font for emphasis */
    font-size: 3rem;
    /* Large text size for heading */
    margin-bottom: 40px;
    /* Space below the title for clear separation */
}

/* Media Queries */

/* Adjust content padding on screens smaller than 1400px */
@media screen and (max-width:1400px) {
    .content {
        padding-left: 0;
        /* Removes padding on the left for smaller screens */
    }
}

/* Flexbox layout for button grouping, centering items horizontally and vertically */
.buttonSwitch {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Subtitle styles - smaller heading elements with specific styling */
.subTitle {
    font-family: "Readex Pro Bold";
    /* Consistent bold font for headings */
    font-size: 1.5rem;
    /* Medium size for subtitle text */
    color: white;
    /* White color for readability */
    padding-left: 20px;
    /* Padding on the left for alignment */
    margin-top: 5px;
    /* Top margin for spacing */
    padding-top: 5px;
    /* Top padding for visual effect */
}

/* Description text style - detailed text explanations */
.description {
    padding-left: 20px;
    /* Left padding aligns text with the subtitle */
    margin-bottom: 5px;
    /* Small bottom margin for tight grouping */
    padding-bottom: 5px;
    /* Bottom padding for visual balance */
    font-family: "Nunito Sans";
    /* Sans-serif font for readability */
    color: #61ce70;
    /* Light green color for thematic consistency */
}

/* Starting and ending paragraphs styles */
.starting,
.ending {
    font-family: "Nunito Sans";
    /* Consistent readable font */
    color: #ffffff;
    /* White text color for contrast */
    font-size: 1.2rem;
    /* Slightly larger text for emphasis */
}

/* Container for types of dementia with a visual highlight */
.dTypeWrapper {
    box-sizing: border-box;
    border-left: 5px solid #61ce70;
    /* Highlighting border on the left */
    background-color: #354d3c;
    /* Dark green background for content emphasis */
}

/* Additional subtitle styles for different sections */
.subTitleSecond {
    font-family: "Readex Pro Bold";
    font-size: 2rem;
    /* Larger text for important subtitles */
    color: white;
    padding-left: 100px;
    /* Larger left padding for distinct sectioning */
    margin-top: 5px;
    padding-top: 5px;
}

/* Secondary content styles for further details and insights */
.contentSecond {
    padding-left: 100px;
    /* Consistent left padding with subTitleSecond */
    color: #abbaaf;
    /* Muted green text for subtlety */
    font-size: 1.3rem;
    /* Comfortable reading size */
    margin-bottom: 20px;
    /* Spacing below paragraphs */
}

/* Styling for action button */
.myBut {
    cursor: pointer;
    /* Indicates the element is clickable */
    margin-left: 100px;
    /* Left margin for alignment */
    margin-top: 20px;
    /* Top margin for spacing */
    background-color: #61ce70;
    /* Light green background for visibility */
    border: none;
    /* No border for a clean appearance */
    display: flex;
    /* Flexbox for internal centering */
    align-items: center;
    /* Vertically aligns text and icons inside the button */
    justify-content: space-around;
    /* Evenly spaces elements within the button */
    padding: 10px;
    /* Padding for a tactile feel */
    font-family: "Nunito Sans";
    /* Readable font style */
    font-size: 1.2rem;
    /* Size of the button text */
    font-weight: 600;
    /* Slightly bolder text for emphasis */
    color: #203827;
    /* Dark green text for contrast */
    border-radius: 20px;
    /* Rounded corners for a friendly appearance */
    width: 450px;
    /* Fixed width for consistency */
}

/* Center alignment styles for content within divs */
.centercenter {
    display: flex;
    justify-content: flex-start;
    /* Aligns children to the start of the flex container */
    align-items: center;
    /* Centers children vertically */
}

/* Hover effect for the button */
.myBut:hover {
    color: white;
    /* Changes text color to white on hover */
    background-color: #354d3c;
    /* Dark green background on hover */
}

/* Icon styling in the button */
.myBut i {
    margin-left: 2%;
    /* Slight separation from the text */
    transform: rotate(-45deg);
    /* Rotates icon for visual flair */
}

/* Additional styles for third type subtitles */
.subTitleThird {
    font-family: "Readex Pro Bold";
    font-size: 2rem;
    /* Larger text for clear readability */
    color: white;
    margin-top: 5px;
    padding-top: 5px;
}

/* Flexbox layout for complex data presentations */
.wrapperThird {
    display: flex;
    justify-content: flex-start;
    /* Aligns items to the start */
    align-items: flex-start;
    /* Aligns items to the top */
    margin-top: 20px;
}

/* Detailed content styles for third type */
.contentThird {
    color: #abbaaf;
    /* Muted green text for thematic consistency */
    font-size: 1.3rem;
    /* Comfortable reading size */
    margin-bottom: 10px;
    /* Spacing below each item */
}

/* Styling for tables within contentThird */
.contentThird th,
.contentThird td {
    border: 1px solid rgb(160, 160, 160);
    /* Light grey borders for cell definition */
    padding: 8px 10px;
    /* Padding for cell content */
    box-sizing: border-box;
    text-align: center;
    /* Center aligns all text within cells */
    color: white;
    /* White text for contrast */
}

/* Header specific styles in tables */
.contentThird th {
    font-size: 1.3rem;
    /* Larger text for header cells */
}

/* Data cell specific styles */
.contentThird td {
    display: table-cell;
    vertical-align: inherit;
    /* Aligns text vertically to the inherit setting */
    unicode-bidi: isolate;
    /* Isolates the bidirectional algorithm for the cell */
    font-size: 1rem;
    /* Standard text size for data cells */
}

/* Styling for the entire table within contentThird */
.contentThird table {
    border-collapse: collapse;
    /* Collapses border to single border shared between cells */
    border: 2px solid rgb(140, 140, 140);
    /* Darker grey border for overall table */
    font-family: sans-serif;
    /* Sans-serif font for readability */
    font-size: 0.8rem;
    /* Smaller text size for data density */
    letter-spacing: 1px;
    /* Slightly spaced letters for legibility */
    text-indent: initial;
    /* Default text indentation */
    border-spacing: 2px;
    /* Space between borders of adjacent cells */
    margin-right: 30px;
    /* Right margin for positioning */
    background-color: #354d3c;
    /* Dark green background for the table */
}

/* Margin style for content within contentThird */
.contentThirdContent {
    margin-bottom: 10px;
    /* Space below the content */
}