.mobile {
    cursor: pointer;
    width: 1.3rem;
    font-size: 1.3rem;
}

.navbar_mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 0;
    position: fixed;
    top: 0;
    right: -100vw;
    width: 100vw;
    height: 100vh;
    background: #2a3239;
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.06);
    padding: 40px 0 0 10px;
    transition: 0.3s ease-in-out;
}

.nav_mobile img {
    cursor: pointer;
}

.navbar_mobile.active {
    right: 0px;
}

.navbar_mobile li {
    margin-bottom: 25px;
}

.navbar_mobile .mobile {
    align-self: flex-end;
    margin-right: 30px;
    margin-bottom: 30px;
}

.nav_mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    padding: 5px 40px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
    position: sticky;
    width: 100%;
    z-index: 999;
}

.logo {
    width: 20%;
    cursor: pointer;
}

@media screen and (max-width:1080px) {
    .navbar li div {
        font-size: 1rem;
    }
}

.nav,
.nav_scroll {
    display: flex;
    align-items: center;
    padding: 0px 50px;
    position: fixed;
    width: 100%;
    z-index: 999;
}

.nav {
    background-color: rgba(0, 0, 0, 0);
    box-shadow: 0 1px 1px 1px rgba(255, 255, 255, 0.06);
}

.nav_scroll {
    background-color: white;
    box-shadow: 0 3px 20px 1px black;
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex: 1;
    margin-bottom: 0;
}

.navbar li,
.navbar_mobile li {
    list-style: none;
    margin: 0 20px;
    position: relative;
}

.text {
    color: white;
}

.scroll_text {
    color: black;
}

.text,
.scroll_text {
    font-family: "Aharoni Bold V1";
    text-decoration: none;
    font-size: 1.3rem;
    font-weight: 600;
    cursor: pointer;
    padding: 30px 0px;
}

.text:hover,
.text:active,
.scroll_text:hover,
.scroll_text:active,
.dropdownText:hover,
.dropdownText:active {
    color: #17cf97;
}

.text::after,
.scroll_text::after,
.dropdownText::after {
    content: "";
    width: 0;
    height: 10px;
    background: #17cf97;
    position: absolute;
    bottom: 0px;
    left: 0;
    transition: width 0.2s;
}

.text:hover::after,
.scroll_text:hover::after,
.dropdownText:hover::after {
    width: 100%;
}

.dropdown {
    font-family: "Aharoni Bold V1";
    box-shadow: 0 2px 15px 1px black;
    width: 100%;
    position: absolute;
    display: none;
    padding: 0;
    list-style: none;
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.navbar>li {
    position: relative;
}

.dropdownText {
    padding: 20px 0px;
    color: black;
    font-size: 1rem;
    cursor: pointer;
}

.navbar_item:hover .dropdown {
    display: block;
}