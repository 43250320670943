
.bg{
    background-color: #f9f9f9;
}


.my_img {
    width: 80%;
    border-radius: 25px;
    margin-bottom: 50px;
}

.descriptions {
    font-family: Nunito Sans;
    font-size: 1.2rem;
    margin-bottom: 40px;
}

.exitButton {
    position: absolute;
    top: 20px;
    // left: 20px;
    border: none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 2px 10px 1px black;
    background-color: #4d7058;
    color: white;
    padding: 10px 100px;
    transition: 0.2s;
}

.exitButton:hover {
    background-color: #61ce70;
    color: black;
}

.exitButton i {
    margin-right: 20px;
}

.mainButton {
    color: white;
    font-size: 1.3rem;
    font-weight: 600;
    background-color: #61ce70;
    border: none;
    border-radius: 10px;
    width: 360px;
    pointer-events: auto;
    padding: 10px 20px;
    transition: 0.15s;
}

.mainButton i {
    margin-left: 2%;
    transform: rotate(-45deg);
}

.mainButton:hover {
    cursor: pointer;
    background-color: #354d3c;
}

.tetris {
    position: relative;
}

.tetris_bg {
    background-image: url("../../../../pictures/brainGames/tetris_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width:550px) {
    .mainButton {
        width: 280px;
    }
}

@media screen and (max-width:600px) {
    .tetris_bg{
        display: none;
    }
}
