/* Title style - applied to main heading elements within the application */
.title {
    color: white;
    /* White text for high contrast against dark background */
    border-bottom: 2px solid #61ce70;
    /* Light green bottom border for visual separation */
    font-family: "Readex Pro Bold";
    /* Custom bold font for emphasis */
    font-size: 3rem;
    /* Large text size for heading */
    margin-bottom: 40px;
    /* Space below the title for clear separation */
}

/* Starting and ending paragraphs styles */
.starting,
.ending {
    font-family: "Nunito Sans";
    /* Consistent readable font */
    color: #ffffff;
    /* White text color for contrast */
    font-size: 1.2rem;
    /* Slightly larger text for emphasis */
}

/* Container for types of dementia with a visual highlight */
.dTypeWrapper {
    box-sizing: border-box;
    border-left: 5px solid #61ce70;
    /* Highlighting border on the left */
    background-color: #354d3c;
    /* Dark green background for content emphasis */
}

/* Subtitle styles - smaller heading elements with specific styling */
.subTitle {
    font-family: "Readex Pro Bold";
    /* Consistent bold font for headings */
    font-size: 1.5rem;
    /* Medium size for subtitle text */
    color: white;
    /* White color for readability */
    padding-left: 20px;
    /* Padding on the left for alignment */
    margin-top: 5px;
    /* Top margin for spacing */
    padding-top: 5px;
    /* Top padding for visual effect */
}

/* Description text style - detailed text explanations */
.description {
    padding-left: 20px;
    /* Left padding aligns text with the subtitle */
    margin-bottom: 5px;
    /* Small bottom margin for tight grouping */
    padding-bottom: 5px;
    /* Bottom padding for visual balance */
    font-family: "Nunito Sans";
    /* Sans-serif font for readability */
    color: #61ce70;
    /* Light green color for thematic consistency */
}