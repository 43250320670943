.scrollBut {
    position: fixed;
    bottom: 50px;
    right: 50px;
    width: 80px;
    height: 80px;
    z-index: 5;
    background-color: #61ce70;
    font-size: 2rem;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0 5px 10px 1px grey;
}

.scrollBut:hover {
    background-color: #354d3c;
}

@media screen and (max-width:500px) {
    .scrollBut {
        font-size: 1rem;
        width: 40px;
        height: 40px;
        bottom: 25px;
        right: 25px;
    }
}

.fadeIn {
    opacity: 0;
    transform: translateY(200px);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.fadeIn.visible {
    opacity: 1;
    transform: translateY(0);
}