/* Styles for tooltip used in the bubble chart */
.tooltip_bubble {
    position: absolute;
    /* Positions the tooltip relative to the nearest positioned ancestor */
    text-align: center;
    /* Centers the text within the tooltip */
    padding: 8px;
    /* Adds 8 pixels of padding around the content inside the tooltip */
    font: 12px sans-serif;
    /* Sets the font size to 12 pixels and the font family to sans-serif */
    background: #F0F0F0;
    /* Sets a light grey background color for the tooltip */
    border: 1px solid #333;
    /* Adds a 1 pixel solid border with a dark grey color */
    border-radius: 5px;
    /* Rounds the corners of the tooltip with a radius of 5 pixels */
    pointer-events: none;
    /* Ensures that the tooltip does not interfere with mouse events, allowing events to pass through to underlying elements */
}