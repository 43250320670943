h1 {
  text-align: center; /* Center align the text */
  grid-area: title; /* Assign grid area */
}

button {
  padding: 4px; /* Add padding to the button */
}

.game-container {
  display: flex; /* Display flex */
  align-items: start; /* Align items to the start */
}


.alert {
  text-align: center; /* Center align the text */
  width: 500px; /* Set width */
  height: 30px; /* Set height */
  margin-left: 34%; /* Set left margin */
  line-height: 0; /* Set line height */
}

.board23 {
  user-select: none; /* Disable user selection */
  margin: auto; /* Center align horizontally */
  grid-area: game; /* Assign grid area */
  margin-left: 41%; /* Set left margin */
  width: 300px; /* Set width */
  height: auto; /* Set height to auto */
  margin-top: 0; /* Set top margin to 0 */
}

.mb-2 {
  display: flex; /* Display flex */
  gap: 10px; /* Set gap between items */
}

.controls {
  grid-area: controls; /* Assign grid area */
  margin-right: auto; /* Set right margin */
}

.row {
  display: flex; /* Display flex */
}

.upcoming {
  display: flex; /* Display flex */
  flex-direction: row-reverse; /* Reverse the direction of flex items */
  justify-content: flex-end; /* Align items to the end */
  gap: 40px; /* Set gap between items */
  margin-bottom: 20px; /* Set bottom margin */
}

.cell1 {
  width: 30px; /* Set width */
  aspect-ratio: 1; /* Maintain aspect ratio */
  border: 1px solid black; /* Add border */
}
.cell1.hidden {
  visibility: hidden; /* Hide the cell */
}

.cell1.Empty {
  background-color: white; /* Set background color */
}

.cell1.I {
  background-color: rgb(80, 227, 230); /* Set background color */
}

.cell1.J {
  background-color: rgb(36, 95, 223); /* Set background color */
}

.cell1.L {
  background-color: rgb(223, 173, 36); /* Set background color */
}

.cell1.O {
  background-color: rgb(223, 217, 36); /* Set background color */
}

.cell1.S {
  background-color: rgb(48, 211, 56); /* Set background color */
}

.cell1.T {
  background-color: rgb(132, 61, 198); /* Set background color */
}

.cell1.Z {
  background-color: rgb(227, 78, 78); /* Set background color */
}

.app {
  margin: 50px 0; /* Set margin */
}