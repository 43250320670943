/* Styles for the root element */
#root {}

/* Styles for the container of the crossword */
.CW-container {
    display: flex;
}

/* Styles for the grid container */
.grid_container {
    flex: 1 1 auto;
    /* Adjust based on your preference */
    position: relative;
    /* Space between grid and clues */
    max-width: 600px;

    max-height: 600px;


    margin-left: 450px;
    margin-bottom: 50px;
}

/* Styles for the clues container */
.clues-container {

    width: auto;

    margin-left: 20px;
}

/* Styles for each cell in the grid */
.cell {
    
    width: 70%;
    height: 50%;
}
/* Remove outline when focused */
*:focus {
    outline: none;
}

/* Styles for input elements */
.input {
    background-color: transparent;
    font-size: 8px;
    border: 0;
    padding: 2px;
    text-transform: uppercase;
}

/* Styles for small text */
.small {
    font: 3px sans-serif;
    text-transform: uppercase;
}

/* Styles for heavy text */
.heavy {
    font: bold 7px sans-serif;
    text-transform: uppercase;
}

/* Styles for the currently active element */
.current {
    z-index: 999;
}

/* Styles for left-aligned clues */
.clueleft {
    font-size: 0.8em;
    width: 50%;
    margin: 0 auto;
}

/* Hover effect for clues */
.clue:hover {
    cursor: pointer;
}

/* Styles for editing mode */
.editing {
    background-color: rgba(255, 200, 111, 0.5);
    border: 1px solid rgb(253, 180, 61);
    /*background-image: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0.5) 0%,
            rgba(0, 0, 0, 0.5) 100%
        ),
        radial-gradient(
            at 50% 0%,
            rgba(255, 255, 255, 0.1) 0%,
            rgba(0, 0, 0, 0.5) 50%
        ); */
    /* background-blend-mode: soft-light, screen; */
    border-radius: 4px;
    color: rgba(0, 20, 15, 0.9);
}


/* Styles for clues list items */
.clues li {
    padding: 8px;
}

/* Styles for buttons */
.button {
    text-align: center;
    padding: 5px 7px;
    margin: 8px;
    font-size: 14px;
    background-color: hsl(0, 0%, 94%);
    border-radius: 10px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    border: 0;
    cursor: pointer;
    opacity: 0.9;
    height: auto;
    width: auto;
}

/* Styles for debug buttons */
.dbg-button {
    text-align: center;
    background-color: hsl(78, 100%, 78%);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    border: 0;
    cursor: pointer;
    opacity: 0.9;
    padding: 5px 7px;
    margin: 4px;
    font-size: 10px;
}

/* .button:hover {
    background-color: rgba(0, 200, 250, 0.5);
} */

/* Styles for crossword buttons container */
.buttons_crossword {
    overflow-y: auto;
    margin-top: auto;
    /*sticky to bottom*/
    height: auto;

    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: #c9ccd3;
    background-image: linear-gradient(-180deg,
            rgba(255, 255, 255, 0.2) 0%,
            rgba(0, 0, 0, 0.5) 100%);
    background-blend-mode: lighten;
    -webkit-box-shadow: 0px -6px 29px -7px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px -6px 29px -7px rgba(0, 0, 0, 0.2);
    box-shadow: 0px -6px 29px -7px rgba(0, 0, 0, 0.2);
}

/* Styles for clear-all button */
.clear-all {
    background-color: rgba(255, 0, 43, 0.4);
    border: 1px solid rgb(170, 24, 48);
    /* color: white; */
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
}

/* Styles for check-all button */
.check-all {
    background-color: rgba(105, 243, 6, 0.4);
    border: 1px solid rgb(24, 170, 48);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
}

/* Styles for clues list container */
.clues {
    padding: 5px;
    overflow: scroll;
    /* border: 1px solid #c9ccd3; */
}

/* Styles for debugger */
#debugger {
    background-color: rgb(215, 233, 250);
    padding: 3px;
}

/* Styles for div header */
#mydivheader {
    background-color: rgb(153, 218, 255);
    border-radius: 4px;
    padding: 3px;
    font-size: 12px;
}

/* Styles for hiding elements */
.hide {
    display: none;
}

/* Styles for across and down clues */
.clues-across,
.clues-down {
    flex: 1;
    margin: 5px;
    padding: 10px;
    overflow-y: auto;
    /* Scroll bars for clues */
}