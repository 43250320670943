/* Styles for flex row layout */
.flexRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 60px;
    margin-bottom: 60px;
}

/* Styles for the main button */
.mainButton {
    margin-top: 30px;
    color: white;
    font-size: 20px;
    font-weight: 600;
    background-color: #61ce70;
    border: none;
    border-radius: 10px;
    pointer-events: auto;
    padding: 10px;
    transition: 0.15s;
    width: 150px
}

/* Styles for the arrow icon inside the main button */
.mainButton i {
    margin-left: 2%;
    transform: rotate(-45deg);
}

/* Hover effect for the main button */
.mainButton:hover {
    cursor: pointer;
    background-color: #354d3c;
}

/* Styles for the star message */
.starMsg{
    color: #61ce70;
}

/* Styles for the title */
.title {
    font-family: "Readex Pro Bold";
    font-size: 2.5rem;
}

/* Styles for the descriptions */
.descriptions {
    font-family: Nunito Sans;
    font-weight: 600;
    color:#878987;
}

/* Styles for the box layout */
.box{
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}