/* Styles for button wrapper */
.fn-btn-wrapper {
  text-align: center; /* Center-align content within the button wrapper */
}

/* Styles for the container of function buttons */
.function-buttons {
  display: flex; /* Use flexbox for layout */
  justify-content: space-around; /* Distribute space evenly between buttons */
}

/* Styles for each function button */
.fn-btn {
  display: flex; /* Use flexbox for layout */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  border: 1px solid #ABABAB; /* Add border to the button */
  width: 75px; /* Set width of the button */
  height: 75px; /* Set height of the button */
  border-radius: 50%; /* Make button circular */
  cursor: pointer; /* Set cursor to pointer */
  background-color: #E6E6E6; /* Set background color of the button */
}

/* Styles for images within function buttons */
.fn-btn img {
  border-radius: 50%; /* Make image circular */
  width: 75%; /* Set width of the image */
  height: 75%; /* Set height of the image */
}

/* Hover effect for function buttons */
.fn-btn:hover {
  background-color: #C8C8C8; /* Change background color on hover */
}


/* Active state for function buttons */
.fn-btn:active {
  background-color: #ABABAB; /* Change background color when button is clicked */
}