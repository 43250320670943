.wizardWrapper {
    padding: 40px 15% 80px 15%;
    background-color: #f9f9f9;
}

@media screen and (max-width:880px) {
    .wrapper {
        margin: 50px 0%;
    }
}

.inputs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.questionTitle {
    margin-bottom: 40px;
    font-family: Nunito Sans;
}

.word {
    color: #61ce70;
    font-weight: 400;
}

.inputWrapper {
    text-align: start;
    margin: 10px 20px;
}

.inputLabel {
    font-family: Nunito Sans;
    font-size: 1rem;
    font-weight: 500;
    color: #61ce70;
}

.wordButton {
    background-color: #61ce70;
    color: white;
    padding: 0 5px;
    border-radius: 5px;
    font-size: 1.3rem;
}

.errorText {
    color: red;
}


.low,
.medium,
.high {
    font-size: 3rem;
    font-family: "Readex Pro Bold";
    margin-bottom: 60px;
}

.low {
    color: rgb(90, 253, 90);
    box-shadow: 0 15px 10px -15px rgb(90, 253, 90);
}

.medium {
    color: rgb(201, 201, 247);
    box-shadow: 0 15px 10px -15px rgb(201, 201, 247);
}

.high {
    color: red;
    box-shadow: 0 15px 10px -15px red;
}

.recommend {
    font-family: Nunito Sans;
    font-size: 1.3rem;
    padding: 0 10%;
}

.recommend i {
    margin-left: 2%;
    transform: rotate(-45deg);
}

.myBut i{
    margin-left: 2%;
    transform: rotate(-45deg);
}

.myBut {
    transform: translateY(-15px);
}

.myBut,
.recommend button {
    background-color: #36d179;
    border: 2px solid #36d179;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 0px 10px;
    padding: 0px 10px;
    width: 180px;
    font-family: "Readex Pro Medium";
    color: white;
}

.myBut:hover,
.recommend button:hover {
    background-color: white;
    color: #36d179;
}



/* HTML: <div class="loader"></div> */
.loader {
    width: 100px;
    height: 60px;
    display: flex;
    animation: l12-0 2s infinite linear;
}

.loader::before,
.loader::after {
    content: "";
    flex: 4;
    background:
        radial-gradient(at 50% 20%, #0000, #000a) bottom left/20px 20px repeat-x,
        linear-gradient(red 0 0) bottom/100% 20px no-repeat #ddd;
    -webkit-mask:
        repeating-linear-gradient(90deg, #000 0 4px, #0000 0 20px) 8px 0,
        radial-gradient(farthest-side, #000 90%, #0000) left bottom/20px 20px repeat-x;
}

.loader::after {
    flex: 1;
    transform-origin: top;
    animation: l12-1 1s cubic-bezier(0, 20, 1, 20) infinite;
}

@keyframes l12-0 {

    0%,
    49.9% {
        transform: scaleX(1)
    }

    50%,
    100% {
        transform: scaleX(-1)
    }
}

@keyframes l12-1 {
    100% {
        transform: rotate(-2deg)
    }
}

.predicting {
    display: flex;
    justify-content: center;
    align-items: center;
}

.predicting .loader{
    margin-bottom: 40px;
    margin-left: 30px;
}

@media screen and (max-width:880px) {
    .predicting {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .predicting .loader{
        margin-bottom: 0;
        margin-left: 0;
    }
}