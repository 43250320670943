.switch {
    position: relative;
    height: 50px;
    width: 510px;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    background: #D7D7D7;
    border-radius: 32px;
}

.switch_label {
    font-weight: bold;
    position: relative;
    z-index: 2;
    float: left;
    width: 170px;
    line-height: 50px;
    font-size: 1.5rem;
    color: #676a6c;
    text-align: center;
    cursor: pointer;
}

.switch_input {
    display: none;
}

.switch_input:checked+.switch_label {
    color: #FFF;
    transition: 0.15s ease-out;
    transition-property: color, text-shadow;
}

.switch_input:checked+.switch_label_y~.switch_selector {
    transform: translateX(0%);
    background-color: #61ce70;
}

.switch_input:checked+.switch_label_i~.switch_selector {
    transform: translateX(100%);
    background-color: #61ce70;
}

.switch_input:checked+.switch_label_n~.switch_selector {
    transform: translateX(200%);
    background-color: #61ce70;
}


.switch_selector {
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 0px;
    display: block;
    width: 170px;
    height: 50px;
    border-radius: 32px;
    background-color: #1ab394;
    transition: all 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.title {
    font-size: 3rem;
    font-family: "Readex Pro Bold";
    padding-bottom: 30px;
    padding-top: 80px;
    text-align: center;
}


@media screen and (max-width:550px){
    .switch {
        width: 300px;
    }

    .switch_label {
        width:100px;
    }

    .switch_selector {
        width:100px;
    }

    .title{
        font-size: 2rem;
    }

    .switch_label{
        font-size: 1rem;
    }

}
