/* General styles for the chart area */
.chart {
    display: flex;
    /* Uses flexbox layout to align child elements */
    justify-content: space-between;
    /* Spreads children evenly with space between */
}

/* Base styles for all bars in the chart */
.bar {
    fill-opacity: 0.8;
    /* Sets the transparency of the bars to 80% */
}

/* Specific styles for bars representing male data */
.male {
    fill: steelblue;
    /* Sets the color of male bars to steel blue */
}

/* Specific styles for bars representing female data */
.female {
    fill: salmon;
    /* Sets the color of female bars to salmon */
}

/* Styles for text elements within any axis of the chart */
.axis text {
    font-size: 12px;
    /* Sets the font size of axis text to 12 pixels */
}

/* Styles for the tooltip used in the chart */
.tooltip {
    position: absolute;
    /* Positions the tooltip relative to the nearest positioned ancestor */
    background-color: #fff;
    /* Sets the background color of the tooltip to white */
    border: 1px solid #ddd;
    /* Adds a solid border with a light gray color */
    padding: 5px;
    /* Adds padding inside the tooltip */
    pointer-events: none;
    /* Ensures that the tooltip does not interfere with mouse events */
    display: none;
    /* Initially hides the tooltip until it's shown via JavaScript */
}

/* Styles to control the size of the SVG element within the bar chart wrapper */
.barchartWrapper svg {
    width: 900px;
    /* Sets the width of the SVG to 900 pixels */
    height: 450px;
    /* Sets the height of the SVG to 450 pixels */
}