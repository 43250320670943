/* Styles for the container element of the button */
.wrapper {
    width: 450px;
    /* Set the width of the button container */
    height: 150px;
    /* Set the height of the button container */
    background-color: #354d3c;
    /* Dark green background color */
    padding: 20px;
    /* Padding around the content within the container */
    cursor: pointer;
    /* Change the mouse cursor to a pointer to indicate it's clickable */
    box-sizing: border-box;
    /* Include padding and border in the element's total width and height */
    display: flex;
    /* Use flexbox layout to align children horizontally */
    justify-content: space-between;
    /* Distribute children with space between them */
    align-items: center;
    /* Vertically center the children */
    margin-bottom: 50px;
    /* Space below the button */
}

/* Base styles for icons within the button */
.icon {
    margin-left: 0px;
    /* No margin on the left of the icon */
    padding: 20px;
    /* Padding around the icon */
    border-radius: 20px;
    /* Rounded corners with 20px radius */
    width: 100px;
    /* Fixed width for the icon */
    height: 100px;
    /* Fixed height for the icon */
}

/* Styles for the icon when the button is in its active or hovered state */
.icon_change {
    margin-left: 0px;
    /* No margin on the left of the icon */
    padding: 20px;
    /* Padding around the icon */
    border-radius: 20px;
    /* Rounded corners with 20px radius */
    width: 100px;
    /* Fixed width for the icon */
    height: 100px;
    /* Fixed height for the icon */
    background-color: white;
    /* Change background to white when active/hovered */
}

/* Base styles for the title text within the button */
.title {
    font-size: 1.5rem;
    /* Font size of 1.5 rem */
    font-family: "Readex Pro Bold";
    /* Custom font family */
    color: white;
    /* Text color set to white */
}

/* Styles for the title text when the button is in its active or hovered state */
.title_change {
    color: black;
    /* Change text color to black when active/hovered */
    font-size: 1.5rem;
    /* Font size remains the same */
    font-family: "Readex Pro Bold";
    /* Font family remains the same */
}