/* Styling for the background */
.bg {
    background-color: #203827; /* Dark green background */
}

/* Styling for the title */
.title {
    font-family: "Readex Pro Bold"; /* Font family */
    font-size: 4rem; /* Font size */
    color: white; /* Text color */
}

/* Styling for the subtitle */
.subtitle{
    font-family: "Readex Pro Medium"; /* Font family */
    font-size: 1.5rem; /* Font size */
    color: #abbaaf; /* Text color */
}

/* Styling for the image */
.image {
    width:100%; /* Image width */
    border-radius: 20px; /* Border radius */
    box-shadow: 0 0 20px 1px white; /* Box shadow */
}

/* Padding for the row */
.row {
    padding: 70px 0; /* Vertical padding */
}

/* Styling for the small word */
.smallword {
    color: #5bca70; /* Text color */
}

/* Styling for the wrapper */
.wrapper {
    height:100%; /* Wrapper height */
    display:flex; /* Flex layout */
    justify-content: space-around; /* Evenly distribute items horizontally */
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start; /* Align items to the start */
    padding-left:70px; /* Left padding */
}

.mainButton {
    margin-top: 30px;
    color: white;
    font-size: 25px;
    font-weight: 600;
    background-color: #61ce70;
    margin-right: 5%;
    border: none;
    border-radius: 10px;
    width: 45%;
    pointer-events: auto;
    padding: 16px;
    transition: 0.15s;
}

/* Media query for smaller screens */
@media screen and (max-width: 991px) {
    /* Adjusting font size for title on smaller screens */
    .title {
        font-size: 40px;
    }
    /* Adjusting font size for subtitle on smaller screens */
    .subtitle{
        font-size: 1.2rem;
    }
    /* Adjusting padding and alignment for wrapper on smaller screens */
    .wrapper {
        padding-top: 40px; /* Top padding */
        padding-left: 0; /* Remove left padding */
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
    }
    /* Adjusting font size, padding, and margin for mainButton on smaller screens */
    .mainButton {
        font-size:15px; /* Font size */
        padding: 10px; /* Padding */
        margin-right: 0; /* Remove right margin */
    }
}

/* Styling for the buttons */
.buttons {
    margin-top: 50px; /* Top margin */
}

/* Styling for the arrow icon inside the main button */
.mainButton i {
    margin-left: 2%; /* Left margin */
    transform: rotate(-45deg); /* Rotate icon */
}

/* Hover effect for the main button */
.mainButton:hover {
    cursor: pointer; /* Pointer cursor */
    background-color: #354d3c; /* Darker background color */
}

/* Styling for the message */
.message{
    color: #7c867f; /* Text color */
    font-size: 1.3rem; /* Font size */
}
