.imageWrapper {
    width: 100%;
    position: relative;
}

.imageWrapper img {
    width: 100%;
    display: block;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to left, rgba(11, 23, 13, 0) 0%, rgba(11, 23, 13, 1) 60%, rgba(11, 23, 13, 1) 40%);
    z-index: 1;
}

.textBlock {
    position: absolute;
    color: white;
    top: 25%;
    left: 20%;
    width: 50%;
    z-index: 2;
}


@keyframes slideDown {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(50%);
    }
}

.animatedElement {
    width: 10%;
    position: absolute;
    animation: slideDown 10s infinite alternate linear;
    top: 20%;
    left: 5%;
    z-index: 2;
}

.bigWord {
    font-size: 96px;
    font-family: 'Aharoni Bold V1';
}

.smallWord {
    font-size: 32px;
    color: #b6b6b6;
    font-family: Arial, Helvetica, sans-serif;
}

.kelso {
    color: palegreen;
    font-family: "Kelso W00 Light";
}

.buttons {
    margin-top: 50px;
}

.mainButton {
    color: white;
    font-size: 25px;
    font-weight: 600;
    background-color: #61ce70;
    margin-right: 5%;
    border: none;
    border-radius: 10px;
    width: 40%;
    pointer-events: auto;
    padding: 16px;
    transition: 0.15s;
}

.mainButton i {
    margin-left: 2%;
    transform: rotate(-45deg);
}

.mainButton:hover {
    cursor: pointer;
    background-color: #354d3c;
}


/* 576, 768, 992, 1200 */
@media screen and (max-width: 1440px) {
    .bigWord {
        font-size: 75px;
    }

    .smallWord {
        font-size: 25px;
    }

    .mainButton {
        font-size: 20px;
        padding: 10px;
    }
}

@media screen and (max-width: 1130px) {
    .bigWord {
        font-size: 60px;
    }

    .smallWord {
        font-size: 20px;
    }

    .mainButton {
        font-size: 16px;
        padding: 7px;
    }
}

@media screen and (max-width: 910px) {
    .bigWord {
        font-size: 45px;
    }

    .smallWord {
        font-size: 15px;
    }

    .mainButton {
        font-size: 12px;
        padding: 7px;
    }
}

@media screen and (max-width: 690px) {
    .bigWord {
        font-size: 30px;
    }

    .smallWord {
        font-size: 10px;
    }

    .buttons {
        margin-top: 20px;
    }

    .mainButton {
        font-size: 8px;
        padding: 5px;
    }
}

@media screen and (max-width: 470px) {
    .bigWord {
        font-size: 24px;
    }

    .smallWord {
        font-size: 8px;
    }

    .mainButton {
        font-size: 6px;
        padding: 2px;
    }

    .buttons {
        margin-top: 10px;
    }
}

@media screen and (max-width: 375px) {
    .bigWord {
        font-size: 15px;
    }

    .smallWord {
        font-size: 5px;
    }

    .mainButton {
        font-size: 5px;
    }

    .buttons {
        margin-top: 5px;
    }
}

.dementia {
    font-family: "Nimrod MT Std Italic";
    margin-left: 5px;
    font-weight: 600;
    /* color: #93f393; */
    color: yellowgreen;
}


@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.star i {
    color: #93f393;
    margin-left: 5px;
    font-size: 60%;
    animation: rotate 10s linear infinite;
}

@keyframes slideInFromLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.bigWord {
    animation: slideInFromLeft 0.5s ease-out 0s;
}

.smallWord {
    animation: slideInFromLeft 1s ease-out 0s;
}

.buttons {
    animation: slideInFromLeft 2s ease-out 0s;
}