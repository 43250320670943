.image {
    width: 100%;
    border-radius: 20px;
    margin-bottom: 30px;
}

.title {
    font-size: 3rem;
    font-family: "Readex Pro Bold";
    margin-bottom: 15px;
}

.block_description {
    border-left: 5px solid #61ce70;
    font-size: 1.3rem;
    font-family: "Nunito Sans";
    padding: 10px 20px;
    box-shadow: 0 0 10px 1px #f2f2f2;
    color: #a47979;
    margin-bottom: 10px;
}

.aspect_description {
    color: #a47979;
    font-size: 1.5rem;
    font-family: "Nunito Sans";
}

.descriptions li{
    font-size: 1.3rem;
    padding: 15px 0;
}

.keypoints {
    font-size: 1.3rem;
}

.keypoints li {
    padding: 15px 0;
}

.subtitle {
    font-size: 1.3rem;
    font-family: "Readex Pro Bold";
}

.button {
    background-color: #36d179;
    border: 2px solid #36d179;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 10px 20px;
    font-family: "Readex Pro Medium";
    color: white;
}

.button:hover {
    background-color: white;
    color: #36d179;
}

.button i {
    transform: rotate(-45deg);
}
