/* Title style - applied to main heading elements within the application */
.title {
    color: white;
    /* White text for high contrast against dark background */
    border-bottom: 2px solid #61ce70;
    /* Light green bottom border for visual separation */
    font-family: "Readex Pro Bold";
    /* Custom bold font for emphasis */
    font-size: 3rem;
    /* Large text size for heading */
    margin-bottom: 40px;
    /* Space below the title for clear separation */
}

.subtitle {
    font-family: "Readex Pro Bold";
    font-size: 1.5rem;
    /* Larger text for clear readability */
    color: white;
    margin-top: 35px;
    padding-top: 5px;
}

.content {
    color: #abbaaf;
    /* Muted green text for thematic consistency */
    font-size: 1.3rem;
    /* Comfortable reading size */
    margin-bottom: 10px;
    /* Spacing below each item */
}


.sentences {
    display: flex;
}

.left {
    margin-right: 50px;
    width: 400px;
}

.right {
    margin-left: 25px
}