* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.accordion-button.collapsed {
    background-color: #f3f7f8;
    color: black !important;
}

.accordion-button {
    font-size: 1.3rem;
    font-weight: 500;
    background-color: #f3f7f8 !important;
    color: #61ce86 !important;
    box-shadow: none !important;
}

.accordion-item {
    border: none !important;
    margin-bottom: 10px;
}

.accordion-body {
    font-size: 1.1rem;
    font-family: "Nunito Sans";
}

.wizard-title {
    font-size: 3rem;
    font-family: "Readex Pro Bold";
    margin-bottom: 15px !important;
}

.category {
    font-size: 1.5rem !important;
    font-family: "Nunito Sans";
    margin-bottom: 40px !important;
}

.wizard-tab-content {
    margin: 30px 0 !important;
    height: 300px;
    text-align: unset !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.wizard-btn{
    transition:0.15s;
}

.wizard-btn:hover{
    background-color: #354d3c !important;
}

.form-range {
    width: 300px;
}

.form-select {
    width: 300px;
}

select{
    cursor: pointer;
}

@media screen and (max-width:880px) {
    .wizard-btn{
        min-width: 80px !important;
    }

    .wizard-tab-content {
        height: 100%;
    }
}

.combobox-input{
    width: 30vw;
    padding: 15px 15px;
    cursor: pointer;
    border-radius: 10px;
    border: none;
}
