

.bg {
    background-image: linear-gradient(rgba(255, 255, 255, 1), transparent 30%, transparent 70%, rgba(243, 247, 248, 1)), url("../../../../pictures/homeHiking/old_people_hiking_rectangle.png");
    height: 800px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}


.star {
    font-size: 1rem;
    color: #5bca70;
    font-family: "Readex Pro Bold";
}

.content_block {
    box-shadow: 0 0 20px 4px #354d3c;
    width: 70%;
    height: 30%;
    background-image: url("../../../../pictures/homeHiking/content_block_bg.png");
    background-color: #5bca70;
    border-radius: 30px;
    transform: translateY(150px);
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.my_img{
    width: 15%;
}

.my_but {
    background-color: #354d3c;
    padding: 10px 20px;
    color: white;
    transition: 0.3s; 
    border: none;
    border-radius: 20px;
    font-size: 1.3rem;
    font-family: "Readex Pro Bold";
}

.my_but:hover {
    background-color: white;
    color: black;
}

.my_but i {
    transform: rotate(-45deg);
}

.title {
    font-size: 3rem;
    font-family: "Readex Pro Bold";
    color: #354d3c;
}

.textWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 60%;
}

.description {
    font-size: 1.2rem;
    font-family: "Nunito Sans";
    color: #f3f7f8;
}

@media screen and (max-width:1400px){
    .title{
        font-size: 2rem;
    }

    .description{
        font-size: 0.8rem;
    }
    
    .my_but{
        font-size: 0.7rem;
    }

}



@media screen and (max-width:800px){
    .content_block{
        width: 80%;
        height: 80%;
        transform: none;
        flex-direction: column;
    }
    .my_img{
        width: 30%;
    }

}