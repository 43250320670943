/* Styling for the title */
.title {
    font-family: "Readex Pro Bold"; /* Font family */
    display: flex; /* Flex layout */
    justify-content: center; /* Horizontally center content */
    align-items: center; /* Vertically center content */
    margin-top: 50px; /* Top margin */
    font-size: 4rem; /* Font size */
}

/* Styling for the subtitle */
.subtitle {
    margin-bottom: 50px; /* Bottom margin */
    display: flex; /* Flex layout */
    justify-content: center; /* Horizontally center content */
    align-items: center; /* Vertically center content */
    font-family: "Readex Pro Medium"; /* Font family */
    font-size: 1.5rem; /* Font size */
    color: #ad7979; /* Text color */
}

/* Styling for the background */
.bg {
    background-color: #f9f9f9; /* Light gray background */
}

/* Media query for smaller screens */
@media screen and (max-width: 786px) {
    /* Adjusting font size for title on smaller screens */
    .title {
        font-size: 40px; /* Font size */
    }
    /* Adjusting font size for subtitle on smaller screens */
    .subtitle {
        font-size: 1.2rem; /* Font size */
    }
}

/* Styling for the flex row */
.flexRow {
    display: flex; /* Flex layout */
    flex-wrap: wrap; /* Wrap items */
    justify-content: space-around; /* Evenly distribute items horizontally */
}
