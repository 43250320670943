.container{
    display:flex;
    justify-content: center;
    align-items: center;
    position:relative;
}

.path {
    display:flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(158, 226, 163);
    border-radius: 25px;
    position:absolute;
    padding:10px 20px;
    z-index: 3;
}

.path i {
    font-size:30px;
    color: green;
    margin: 0 20px;
}

.text {
    cursor: pointer;
    
}

.textLast,
.text{
    font-family: "Readex Pro Medium";
}

.text:hover {
    color: white;
    transition: 0.15s;
}
