/* Styles for the game box */
.box {
    border-radius: 10px;
    border: 2px solid #ededed;
    transition: 0.1s;
    cursor: pointer;
    padding: 30px 40px;
    box-sizing: border-box;
    position: relative;
}

/* Arrow icon style */
.arrow {
    color: #ededed;
    font-size: 30px;
}

/* Styling on hover */
.box:hover {
    border: 2px solid #61ce70;
    background-color: #f9f9f9;

    .arrow {
        color: #61ce70;
    }

    .title {
        color: #61ce70;
    }

    .gameNum {
        background-color: #61ce70;
        color: white;
        border: 2px solid #61ce70;
        transform: rotateY(360deg);
    }
}

/* Styling for the game box image */
.box img {
    height: 80px;
}

/* Title styling */
.title {
    font-family: "Readex Pro Bold";
    font-size: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
}

/* Description styling */
.description {
    font-family: Nunito Sans;
    font-weight: 400;
    font-size: 16px;
    color: #797979;
}

/* Game number styling */
.gameNum {
    position: absolute;
    right: -2px;
    top: 50px;
    padding-right: 20px;
    padding-left: 10px;
    border: 2px solid #ededed;
    box-sizing: border-box;
    border-right: 2px solid white;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-family: Nunito Sans;
    font-weight: 400;
    font-size: 16px;
    color: #797979;
    transform-style: preserve-3d;
    transition: transform 0.5s;
}