/* Styles for the line elements in the chart */
.line {
    fill: none;
    /* Ensures that the line has no fill color */
    stroke-width: 2px;
    /* Sets the thickness of the line */
}

/* Styles for text elements within any axis of the chart */
.axis text {
    font-size: 12px;
    /* Sets the font size of axis text to 12 pixels */
}

/* Styles for the path and line elements within any axis to enhance visibility */
.axis path,
.axis line {
    fill: none;
    /* No fill color for lines and paths of the axis */
    stroke: #000;
    /* Sets the stroke color to black for visibility */
    shape-rendering: crispEdges;
    /* Optimizes rendering for geometric precision */
}

/* Styles for text elements in the legend */
.legendText {
    font-size: 14px;
    /* Sets the font size to 14 pixels */
    margin-bottom: 5px;
    /* Adds a bottom margin to space out items in the legend */
}

/* Styles for line elements in the legend */
.legendLine {
    stroke-width: 2px;
    /* Sets the thickness of the legend line */
}

/* Styles for the tooltip element */
.tooltip_rate {
    position: absolute;
    /* Positions the tooltip relative to the nearest positioned ancestor */
    background-color: rgba(255, 255, 255, 0.8);
    /* Semi-transparent white background */
    border: 1px solid #aaa;
    /* Light grey border around the tooltip */
    border-radius: 4px;
    /* Rounded corners for the tooltip */
    padding: 6px 10px;
    /* Padding inside the tooltip */
    pointer-events: none;
    /* Ensures that the tooltip does not interfere with mouse events */
    font-size: 14px;
    /* Sets the font size for the tooltip text */
    display: none;
    /* Tooltip is initially hidden and shown on specific actions */
}