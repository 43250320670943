/* Styling for the image wrapper */
.imageWrapper {
    width: 100%;
    position: relative; /* Positioning context for the overlay and textWrapper */
}

/* Styling for the image inside the imageWrapper */
.imageWrapper img {
    width: 100%;
    display: block; /* Ensures the image fills its container */
}

/* Styling for the overlay */
.overlay {
    position: absolute; /* Positioned relative to the imageWrapper */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4); /* Semi-transparent black overlay */
    z-index: 1; /* Places the overlay above the image */
}

/* Styling for the textWrapper */
.textWrapper {
    position:absolute; /* Positioning relative to the imageWrapper */
    z-index:2; /* Places the text above the overlay */
    top:0;
    left:0;
    right:0;
    bottom:0;
    display:flex; /* Flex layout */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    color:white; /* Text color */
    font-family: "Readex Pro Bold"; /* Font family */
    font-size: 80px; /* Font size */
}

/* Styling for the path class */
.path {
    display: flex; /* Flex layout */
    justify-content: space-around; /* Evenly distributes items horizontally */
    align-items: center; /* Center vertically */
}

/* Media query for smaller screens */
@media screen and (max-width:1080px) {
    /* Adjusting font size for textWrapper on smaller screens */
    .textWrapper {
        font-size: 40px;
    }
}
