.wrapper {
    width: 500px;
    height: 150px;
    display: flex;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.vis_img {
    width: 100px;
    height: 100px;
    border-radius: 50px;
}

.content {
    padding-left:20px;
}

.title {
    font-family: "Readex Pro Bold";
    font-size: 1.7rem;
}

.description {
    font-family: Nunito Sans;
    font-weight: 600;
    color: #8f79a4;
}

@media screen and (max-width:1400px) {
    .wrapper{
        margin:0 !important;
        height:200px;
    }
}

@media screen and (max-width:550px) {
    .wrapper{
        width: 300px;
        height: 150px;
    }

    .vis_img{
        width: 50px;
        height: 50px;
    }

    .title{
        font-size: 1rem;
    }

    .description{
        font-size: 0.8rem;
    }
}