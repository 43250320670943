/* Styles for the keypad container */
.keypad {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 300px;
  height: 300px;
  gap: 10px;
}

/* Styles for individual keys */
.key {
  border: 1px solid #ABABAB;
  background-color: #E6E6E6;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
}

/* Hover effect for keys */
.key:hover {
  background-color: #C8C8C8;
}

/* Active state for keys */
.key:active {
  background-color: #ABABAB;
}