.bg {
    background-color: #203827;
}

.suggestmapContainer{
    width: 100%;
    height: 800px;
    // border-radius: 50px;
    margin-bottom: 50px;
}

.inputWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
}

.description {
    font-family: Nunito Sans;
    font-size: 1.2rem;
    color:#abbaaf;
    text-align: center;
}

.title {
    margin-top: 50px;
    text-align: center;
    font-size: 2.5rem;
    color: white;
    font-family: "Readex Pro Bold";
}

.disclaimer{
    margin-top: 10px;
    text-align: center;
    color: palegreen;
    font-family: Nunito Sans;
    font-size: 1.2rem;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.disclaimer i {
    margin-right: 5px;
    font-size: 60%;
    animation: rotate 5s linear infinite;
    font-size: 1.5rem;
}

.description a {
    color: palegreen
}