.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.graph {
    flex: 1;
}

.label {
    color: #61ce6e;
    font-family: "Nunito Sans";
    font-size: 1.2rem;
}

.title {
    flex: 1;
    text-align: center;
    font-family: "Readex Pro Bold";
    color: white;
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.wrapper_left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.tips_wrapper {
    background-color: #354d3c;
    border-left: 8px solid #61ce6e;
    padding: 10px;
}

.tips_sentence {
    font-family: "Nunito Sans";
    color: #61ce6e;
    font-size: 1.1rem;
}

.guide {
    font-family: "Readex Pro Bold";
    color: white;
    font-size: 1.5rem;
}

.relative{
    padding:10px;
}

@media screen and (min-width: 1300px) {
    .relative {
        position: relative;
        top: 70px;
        z-index: 1;
    }
}
