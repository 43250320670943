$cell_with: 1.4vw;
$cell_height: 2.3vh;
$cell_border_radius: 0.7vh;
$cell_border: 1px solid rgba(128, 128, 128, 0.1);


@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.tetris_bg {
    background-color: rgba($color: #FFFFFF, $alpha: 0.8);
    width: 90%;
    height: 80%;
    box-sizing: border-box;
    border: 2px solid yellow;
    box-shadow: inset 0 0 15px 5px black;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
}

.title {
    font-size: 3rem;
    font-family: "Happy Monkey", system-ui;
    font-weight: 900;
    font-style: normal;
}

.mode_but {
    width: 300px;
    background-color: #4d7058;
    text-align: center;
    padding: 20px 0;
    border-radius: 20px;
    color: white;
    font-family: "Happy Monkey", system-ui;
    font-weight: 400;
    font-style: normal;
    font-size: 1.5rem;
    cursor: pointer;
    box-shadow: 0 2px 5px 1px black;
}

.mode_but:hover {
    background-color: #61ce70;
}

.gameWrapper {
    width: 90%;
    height: 90%;
    position: relative;
}

.buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10%;
}

.gameButton,
.backButton,
.instructionButton {
    border: none;
    background-color: #4d7058;
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px 1px black;
    transition: 0.1s;
    font-family: "Happy Monkey", system-ui;
    font-weight: 400;
    font-style: normal;
    font-size: 1.5rem;
}

.gameButton:hover,
.backButton:hover,
.instructionButton:hover {
    background-color: #61ce70;
    color: black;
}

.tetris_guide {
    height: 100%;
    padding-top: 60px;
}

.guide_title {
    font-family: "Happy Monkey", system-ui;
    font-weight: 800;
    font-style: normal;
    font-size: 3rem;
}

.guide_content {
    font-family: "Happy Monkey", system-ui;
    font-weight: 500;
    font-style: normal;
    font-size: 2rem;
}

.blockRowWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.panelsWrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 3%;
    height: 90%;
}

.panelWrapper {
    width: 44%;
    height: 97%;
}

.panel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
}

@keyframes fadeInOut {
    0% { opacity: 0; }
    10% { opacity: 1; }
    90% { opacity: 1; }
    100% { opacity: 0; }
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.banner {
    font-family: "Happy Monkey", system-ui;
    font-weight: 500;
    font-style: normal;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2%;
    height: 20%;
    width: 100%;
}

.panel_left {
    width: 30%;
    height: 100%;
    padding: 0 5%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    background-color: none;
}

.panel_mid {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #242424;
}

.panel_right {
    width: 30%;
    height: 100%;
    // background-color: pink;
    padding: 5% 5%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.hold {
    height: 30%;
    width: 100%;
}

.hold_label {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #61ce70;
    color: black;
    height: 25%;
    font-family: "Happy Monkey", system-ui;
}

.hold_content {
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #354d3c;
    color: white;
    font-family: "Happy Monkey", system-ui;
}

.info {
    height: 30%;
    width: 100%;
}

.info_label {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #61ce70;
    color: black;
    font-family: "Happy Monkey", system-ui;
    height: 25%;
}

.info_content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25%;
    font-family: "Happy Monkey", system-ui;
    background-color: #354d3c;
    color: white;
}

.next {
    height: 100%;
    width: 100%;
}

.next_label {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #61ce70;
    font-family: "Happy Monkey", system-ui;
    color: black;
    height: 20%;
}

.next_content {
    height: 80%;
    background-color: #354d3c;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.boardRowWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cell_empty_board {
    width: $cell_with;
    height: $cell_height;
    background-color: #242424;
    border: $cell_border;
}

.cell_empty_hold {
    width: $cell_with;
    height: $cell_height;
    background-color: #354d3c;
}

.I,
.J,
.O,
.L,
.S,
.T,
.Z {
    width: $cell_with;
    height: $cell_height;
    border-radius: $cell_border_radius;
}

.S {
    border: 2px solid #007f23;
    background-color: #00f35c;
}

.Z {
    border: 2px solid #7f0000;
    background-color: #ee0000;
}

.L {
    border: 2px solid #00437f;
    background-color: #0094f9;
}

.O {
    border: 2px solid #7f7500;
    background-color: #f5dd00;
}

.T {
    border: 2px solid #5e0879;
    background-color: #d000f6;
}

.I {
    border: 2px solid #006274;
    background-color: #00d8f7;
}

.J {
    border: 2px solid #746600;
    background-color: #f8ae00;
}

// S green 00f35c 007f23
// Z red ee0000 7f0000
// L blue 0094f9 00437f
// O yellow f5dd00 7f7500
// T purple d000f6 5e0879
// I blue 00d8f7 006274
// J orange f8ae00 746600

.G {
    width: $cell_with;
    height: $cell_height;
    background-color: grey;
    border-radius: $cell_border_radius;
}

.V {
    width: $cell_with;
    height: $cell_height;
    background-color: #242424;
    box-sizing: border-box;
    border: 1px solid white;
    border-radius: $cell_border_radius;
}

.word{
    font-weight: 900;
    font-size: 3rem;
    color: green;
}