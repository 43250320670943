
/* Styles for the background container */
.bg {
    background-color: #203827; /* Background color */
}

/* Title styling */
.title {
    font-family: "Readex Pro Bold";
    font-size: 4rem;
    color: white;
}

/* Subtitle styling */
.subtitle{
    font-family: "Readex Pro Medium";
    font-size: 1.5rem;
    color: #abbaaf;
}

/* Image styling */
.image {
    width:100%;
    border-radius: 20px;
    box-shadow: 0 0 20px 1px white;
}

/* Row styling */
.row {
    padding: 70px 0;
}

/* Small word styling */
.smallword {
    color: #5bca70;
}

/* Wrapper styling */
.wrapper {
    height:100%;
    display:flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: flex-start;
    padding-left:70px;
}

/* Main button styling */
.mainButton {
    margin-top: 30px;
    color: white;
    font-size: 25px;
    font-weight: 600;
    background-color: #61ce70;
    margin-right: 5%;
    border: none;
    border-radius: 10px;
    width: 45%;
    pointer-events: auto;
    padding: 16px;
    transition: 0.15s;
}

/* Media query for smaller screens */
@media screen and (max-width: 991px) {
    .title {
        font-size: 40px;
    }
    .subtitle{
        font-size: 1.2rem;
    }
    .wrapper {
        padding-top: 40px;
        padding-left: 0;
        justify-content: center;
        align-items: center;
    }
    .mainButton {
        font-size:15px;
        padding: 10px;
        margin-right: 0;
    }
}

/* Additional styles for buttons */
.buttons {
    margin-top: 50px;
}

/* Styling for icon inside main button */
.mainButton i {
    margin-left: 2%;
    transform: rotate(-45deg);
}

/* Hover effect for main button */
.mainButton:hover {
    cursor: pointer;
    background-color: #354d3c;
}

/* Message styling */
.message{
    color: #7c867f;
    font-size: 1.3rem;
}