/* Styles for the backdrop of the modal */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;
}

/* Styles for the container of the modal */
.modal-container {
  display: grid;
  padding: 15px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: fit-content;
  margin: auto;
  max-width: 600px;
  max-height: 90%;
  background-color: white;
  border-radius: 8px;
  overflow: auto;
  text-align: center;
}

/* Styles for the header of the modal */
.modal-header {
  display: flex;
  align-items: center;
  position: relative;
}

/* Styles for the heading of the modal */
.modal-heading {
  background-color: #F8D7DA;
  border: 1px solid #EDCCD1;
  color: #A94442;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 1.5rem;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
}

/* Styles for the close button of the modal */
.close-modal-btn {
  margin-left: auto;
  width: fit-content;
  height: fit-content;
  border-radius: 50%;
  border: none;
  padding: 8px;
  background-color: #D8DADF;
  color: #757575;
  cursor: pointer;
}

/* Hover effect for the close button */
.close-modal-btn:hover {
  filter: brightness(90%);
}

/* Styles for the nested icons within the close button */
.close-modal-btn .material-symbols-rounded {
  vertical-align: middle;
}

/* Styles for the horizontal ruler within the modal */
.modal-container hr {
  margin: 15px 0;
  border-top: 1px solid #D8DADF;
  border-bottom: none;
}

/* Styles for ordered lists within the modal */
.modal-container ol {
  margin: 15px 35px;
  text-align: left;
  /* list-style: inside; */
}

/* Styles for nested ordered lists within the modal */
.modal-container ol ol {
  margin: 0 25px;
  font-size: 0.8rem;
}