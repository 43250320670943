/* Styles for the title */
.title {
    font-family: "Readex Pro Bold"; /* Set font family */
    display: flex; /* Flex container */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    margin-top: 50px; /* Top margin */
    font-size: 4rem; /* Font size */
}

/* Styles for the subtitle */
.subtitle {
    margin-bottom: 50px; /* Bottom margin */
    display: flex; /* Flex container */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    font-family: "Readex Pro Medium"; /* Set font family */
    font-size: 1.5rem; /* Font size */
    color: #ad7979; /* Text color */
}

/* Background styles */
.bg {
    background-color: #f9f9f9; /* Background color */
}

/* Media query for smaller screens */
@media screen and (max-width: 786px) {
    .title {
        font-size: 40px; /* Adjust font size for smaller screens */
    }
    .subtitle {
        font-size: 1.2rem; /* Adjust font size for smaller screens */
    }
}

/* Styles for flex row */
.flexRow {
    display: flex; /* Flex container */
    flex-wrap: wrap; /* Allow wrapping */
    justify-content: space-around; /* Distribute items evenly */
}