/* Define styles for the wrapper of the image */
.imageWrapper {
    width: 100%; /* Set width to 100% */
    position: relative; /* Make position relative for absolute positioning of child elements */
}

/* Define styles for the image within the wrapper */
.imageWrapper img {
    width: 100%; /* Make the image fill the entire width of its container */
    display: block; /* Make the image a block-level element */
}

/* Define styles for the overlay */
.overlay {
    position: absolute; /* Position the overlay absolutely */
    top: 0; /* Align to the top of the image wrapper */
    left: 0; /* Align to the left of the image wrapper */
    right: 0; /* Align to the right of the image wrapper */
    bottom: 0; /* Align to the bottom of the image wrapper */
    background: rgba(0, 0, 0, 0.4); /* Semi-transparent black background */
    z-index: 1; /* Set z-index to appear above the image */
}

/* Define styles for the text wrapper */
.textWrapper {
    position: absolute; /* Position the text wrapper absolutely */
    z-index: 2; /* Set z-index higher than overlay to appear above it */
    top: 0; /* Align to the top of the image wrapper */
    left: 0; /* Align to the left of the image wrapper */
    right: 0; /* Align to the right of the image wrapper */
    bottom: 0; /* Align to the bottom of the image wrapper */
    display: flex; /* Use flexbox layout */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    color: white; /* Text color */
    font-family: "Readex Pro Bold"; /* Font family */
    font-size: 80px; /* Font size */
}

/* Define styles for the path */
.path {
    display: flex; /* Use flexbox layout */
    justify-content: space-around; /* Distribute items evenly along the main axis */
    align-items: center; /* Center items vertically */
}

/* Media query for screens with max width of 1080px */
@media screen and (max-width: 1080px) {
    /* Adjust font size for text wrapper */
    .textWrapper {
        font-size: 40px; /* Reduce font size for smaller screens */
    }
}
