/* Styles for the grid container */
.grid {
  display: grid;
  grid-template-columns: repeat(9, 1fr); /* 9 columns with equal width */
  width: 450px; /* Set width of the grid */
  height: 450px; /* Set height of the grid */
  border: 4px solid black; /* Add border around the grid */
}

/* Styles for individual tiles */
.tile {
  /* height: 50px; */ /* Commented out height to allow dynamic sizing */
  border: 1px solid black; /* Add border around each tile */
  display: flex; /* Use flexbox for layout */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  user-select: none; /* Disable text selection */
  cursor: pointer; /* Set cursor to pointer */
  font-size: 20px; /* Set font size */
}

/* Styles for right border of tiles */
.right-border {
  border-right: 2px solid black; /* Add right border */
}

/* Styles for left border of tiles */
.left-border {
  border-left: 2px solid black; /* Add left border */
}

/* Styles for top border of tiles */
.top-border {
  border-top: 2px solid black; /* Add top border */
}

/* Styles for bottom border of tiles */
.bottom-border {
  border-bottom: 2px solid black; /* Add bottom border */
}

/* Default styling for tiles */
.default-tiles { 
  background-color: #E6E6E6; /* Set background color for default tiles */
}

/* Styling for selected tiles */
.selected-tile {
  background-color: deepskyblue; /* Set background color for selected tiles */
}


/* Styling for correct input */
.correct {
  color: blue; /* Set text color to blue for correct input */
}

/* Styling for incorrect input */
.incorrect {
  color: red; /* Set text color to red for incorrect input */
}

/* Styling for highlighted tiles */
.highlighted {
  background-color: lightskyblue; /* Set background color for highlighted tiles */
}