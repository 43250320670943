/* Apply padding to the wrapper element */
.wrapper{
    padding: 50px 0;
}

/* Style the icon within the title */
.title i{
    color:#90ce70;
}

/* Style the title text */
.title {
    font-size: 1.3rem;
    font-family: "Readex Pro Bold";
}

/* Style the left navigation container */
.leftNav {
    border-radius: 25px; /* Apply border-radius */
    padding: 20px; /* Apply padding */
    box-shadow: 0 0 5px 5px #f9f9f9; /* Apply box shadow */
}

/* Style the right content container */
.rightContent{
    padding:0px 10px; /* Apply padding */
    height: 1500px; /* Set height */
}
