.wrapper {
    padding: 20px;
    background-color: #f3f7f8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    margin: 20px 0;
}

.wrapper:hover {
    cursor: pointer;
}

.arrow_clicked,
.arrow {
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding: 10px 30px;
    font-size: 1.1rem;
}

.arrow_clicked {
    background-color: #90ce70;
    color: white;
}

.arrow {
    background-color: white;
    color: #90ce70;
}

.text_clicked,
.text {
    font-size: 1.1rem;
    font-family: "Readex Pro Medium";
}

.text_clicked {
    color: #90ce70;
}

.text {
    color: black;
}


@media screen and (max-width: 880px) {
    .text_clicked,
    .text,
    .arrow_clicked,
    .arrow {
        font-size: 0.8rem;
    }

    .wrapper{
        display:block;
    }
}