.placeDetails {
    background-color: #354D3C;
    height: 800px;
    overflow: auto;
    padding: 20px;
    border-width: 10px;
    border-style: solid;
    border-color: #ff7e5f;
}

.reminders {
    color: white;
    font-family: Nunito Sans;
    font-size: 1.2rem;
}

.title {
    color: gold;
    font-family: "Readex Pro Bold";
    font-size: 2.5rem;
    text-align: center;
}

.subTitle {
    color: white;
    font-family: "Readex Pro Bold";
    font-size: 1.7rem;
    margin-bottom: 10px;
    margin-top: 20px;
}


.key {
    color: palegreen
}

.placeDetails p{
    font-family: "Readex Pro Light";
    font-size: 1rem;
    color: white;
    margin: 0;
    padding-left: 10px;
}

.info{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
}

.info img{
    height: 2rem;
}

.reviewItem hr {
    color: white
}