.rectangle {
    width: 250px;
    height: auto;
    background-color: white;
    margin-bottom: 40px;
    cursor: pointer;
    transition: 0.3s;
}

.rectangle:hover {
    transform: translateY(-10px);
    box-shadow: 0 2px 10px 2px grey;

    .readMore::after {
        width: 100%;
        transition: width 0.3s;
    }
}

.picture img {
    width: 100%;
}

.brief {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    color: #797979;
    height: 60px;
    font-family: "Readex Pro Medium";
    border-bottom: 2px solid #c7c3c3;
    margin-left: 20%;
    padding-bottom: 10px;
}

.bottom {
    display: flex;
    height: 100px;
}

.readMore {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    width: 10%;
    margin-right: 10%;
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Readex Pro Medium";
    font-size: 13px;
    transition: 0.2s;
    position: relative;
}

.readMore::after {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    height: 20px;
    bottom: -20px;
    background-color: rgb(124, 245, 124);
}

.readMore:hover {
    background-color: white;
    color: black;
}



.subtitle {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Readex Pro Bold";
    font-size: 23px;
}