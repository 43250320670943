/* Background styling */
.bg {
    background-color: #f3f7f8;
    padding-top: 50px;
    padding-bottom:50px;
}

/* Wrapper container for layout */
.wrapper {
    display:flex;
    justify-content: flex-start;
    align-items: center;
}

/* Left side container */
.left {
    position: relative;
    height: 450px;
}

/* Responsive image styling */
.left img{
    height:100%;
    border-radius: 50px;
}

/* Information block styling */
.infoBlock {
    position: absolute;
    width: 300px;
    height: 350px;
    left: 300px;
    top: 50px;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0 0 20px 1px rgb(179, 179, 179);
    padding: 30px;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;

}

/* Media query for smaller screens */
@media screen and (max-width:1400px) {
    /* Adjust wrapper for vertical layout */
    .wrapper{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    /* Adjust info block for smaller screens */
    .infoBlock{
        width: 300px;
        height:300px;
        left:75px;
        top:75px;
        justify-content: center;
        align-items: center;
        text-align: center;
        line-height: 1.5rem;
    }
}

/* Main button styling */
.mainButton {
    margin-top: 10px;
    color: white;
    font-size: 20px;
    font-weight: 600;
    background-color: #61ce70;
    border: none;
    border-radius: 10px;
    pointer-events: auto;
    padding: 10px;
    transition: 0.15s;
    width: 150px
}

/* Arrow icon styling */
.mainButton i {
    margin-left: 2%;
    transform: rotate(-45deg);
}

/* Hover effect for main button */
.mainButton:hover {
    cursor: pointer;
    background-color: #354d3c;
}

/* Title styling */
.title {
    font-family: "Readex Pro Bold";
    font-size: 2.5rem;
    line-height: 2.5rem;
}

/* Description styling */
.description {
    font-family: Nunito Sans;
    font-weight: 600;
    color:#878987;
}

/* Star message styling */
.starMsg{
    color: #61ce70;
}